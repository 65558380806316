<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div v-if="users.length > 0" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b card-stretch">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title">
              <span class="card-label font-weight-bolder text-dark"
                >All Users</span
              >
            </h3>
            <div class="card-toolbar">
              <router-link
                to="/users/new"
                class="btn btn-mc font-weight-bolder"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  />
                </span>
                Add New User
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-head-bg table-vertical-center"
              >
                <thead>
                  <tr class="bg-gray-100 text-left">
                    <th style="min-width: 250px" class="pl-7">
                      <span @click="orderUserByEmail" class="cursor-pointer">
                        <span
                          class="pt-2"
                          :class="{ 'text-primary': emailOrder.isActive }"
                        >
                          Email</span
                        >
                        <span
                          class="svg-icon svg-icon-sm  pl-2"
                          :class="{
                            'svg-icon-primary': emailOrder.isActive
                          }"
                        >
                          <inline-svg
                            v-if="emailOrder.query === 'asc'"
                            src="media/svg/icons/Navigation/Down-2.svg"
                          />
                          <inline-svg
                            v-else
                            src="media/svg/icons/Navigation/Up-2.svg"
                          />
                        </span>
                      </span>
                    </th>
                    <th style="" class="">Role</th>
                    <th style="min-width: 110px" class="pr-7 text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, key) in users" :key="key">
                    <td
                      class="pl-0 py-4"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-50 flex-shrink-0 mr-4">
                          <div
                            class="symbol-label"
                            style="background-image: url('/metronic/theme/html/demo7/dist/assets/media/stock-600x400/img-26.jpg')"
                          ></div>
                        </div>
                        <div>
                          <router-link
                            :to="'/users/edit/' + user.id"
                            class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >{{ user.email }}</router-link
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        class="label label-lg label-light-primary label-inline"
                        >{{ user.roles[0] }}</span
                      >
                    </td>
                    <td
                      class="pr-0 text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <router-link
                        :to="'/users/edit/' + user.id"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Communication/Write.svg"
                          />
                        </span>
                      </router-link>
                      <button
                        @click="removeUser(user.id)"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg src="media/svg/icons/General/Trash.svg" />
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" type="grow"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'ListCompanies',
  data() {
    return {
      users: [],
      view: {
        previous: null,
        first: '?page=1',
        last: null,
        next: null
      },
      isLoaded: false,
      emailOrder: {
        query: 'asc',
        isActive: false
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Companies', route: '/companies/list' }
    ])

    this.getUsers('/users', `?order[email]=${this.emailOrder.query}`).then(
      ({ data }) => {
        this.users = [...data['hydra:member']]
        this.isLoaded = true
      }
    )
  },
  methods: {
    async getUsers(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async deleteUser(id) {
      try {
        return await ApiService.delete('/users/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    removeUser(id) {
      const confirmation = confirm('You want delete this user?')
      if (confirmation === false) return

      this.deleteUser(id).then(({ status }) => {
        if (status === 204) {
          this.users = this.users.filter(user => user.id !== id)
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'User success deleted from database'
          })
        }
      })
    },
    orderUserByEmail() {
      this.emailOrder.isActive = true
      if (this.emailOrder.query === 'asc') {
        this.emailOrder.query = 'desc'
      } else {
        this.emailOrder.query = 'asc'
      }

      this.isLoaded = false
      this.getUsers('/users', `?order[email]=${this.emailOrder.query}`).then(
        ({ data }) => {
          this.users = []
          this.users = [...data['hydra:member']]
          this.isLoaded = true
        }
      )
    }
  }
}
</script>
